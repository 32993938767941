import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";

import { currentPageState } from "../../state/app";

import { SEOProps } from "./SEO.types";

declare global {
    interface Window {
        dataLayer: {
            pageName?: string;
            event?: string;
            applicationName?: string;
            applicationLanguage?: string;
            applicationCountry?: string;
        }[];
    }
}

const SEO: React.FC<SEOProps> = ({ language, title }) => {
    const page = useRecoilValue(currentPageState);

    useEffect(() => {
        if (
            page &&
            window.dataLayer &&
            window.dataLayer[window.dataLayer.length - 1]?.pageName !==
                page.name
        ) {
            window.dataLayer.push({
                event: "pageInformation",
                applicationName: "LG Ford",
                pageName: page.name,
                applicationLanguage: process.env.GATSBY_CURRENT_LANGUAGE,
                applicationCountry: "BE",
            });
        }
    }, [page]);

    return (
        <>
            <Helmet htmlAttributes={{ lang: language, title }}>
                <title>{title}</title>
                <script
                    id="ga4"
                    type="text/javascript"
                    src="/scripts/analytics.js"
                />
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    data-document-language="true"
                    type="text/javascript"
                    data-domain-script="d47f5568-fea1-4789-bf9d-42f5565e346d"
                ></script>
            </Helmet>
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-KDQ8J54"
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                ></iframe>
            </noscript>
        </>
    );
};

export default SEO;
