import { globalHistory } from "@reach/router";
import { navigate } from "gatsby";
import { useState } from "react";

const makeAdapter = () => {
    return {
        replace(location) {
            return navigate(location.search || "?", {
                replace: true,
                state: {
                    ...location,
                    disableScrollUpdate: true,
                },
            });
        },
        push(location) {
            return navigate(location.search || "?", {
                replace: false,
                state: {
                    ...location,
                    disableScrollUpdate: true,
                },
            });
        },
        get location() {
            return globalHistory.location;
        },
    };
};

export const QueryParamsAdapter = ({ children }) => {
    const [adapter] = useState(makeAdapter);
    return children(adapter);
};
