import { useLocation } from "@reach/router";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { FOOTER_LINKS } from "./Footer.consts";
import { FooterProps } from "./Footer.types";

const Footer: React.FC<FooterProps> = ({ isDark, isCompact }) => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <footer
            className={classNames("footer", {
                "is-dark": isDark,
                "is-compact": isCompact,
            })}
        >
            {FOOTER_LINKS.map((link) => {
                const basePath = location.origin;
                const to = t(`simulator.footer.${link}.link.url`) ?? "";
                const internal = /^\/(?!\/)/.test(to);
                const linkText = t(`simulator.footer.${link}.link.text`);

                return internal ? (
                    // We can not use the Gatsby Link component for internal links
                    // because it can not open the link in a new tab
                    <a
                        className="is-link"
                        key={link}
                        target="_blank"
                        rel="noreferrer"
                        href={`${basePath}${to}`}
                    >
                        {linkText}
                    </a>
                ) : (
                    <a
                        key={link}
                        className="is-link"
                        target="_blank"
                        rel="noreferrer"
                        href={to}
                    >
                        {linkText}
                    </a>
                );
            })}
        </footer>
    );
};

export default Footer;
