import ComputerConnectionBrowserError from "@images/computer-connection-browser-error.svg";
import ComputerConnectionMonitorError from "@images/computer-connection-monitor-error.svg";
import CurrentCar from "@images/currentcarford.svg";
import NewCar from "@images/newcarford.svg";
import SecondHandCar from "@images/secondhandcarford.svg";

export const fetchIconByName = (name: string) => {
    if (name === "NewCar") {
        return NewCar;
    }
    if (name === "CurrentCar") {
        return CurrentCar;
    }
    if (name === "SecondHandCar") {
        return SecondHandCar;
    }
    if (name === "ComputerConnectionMonitorError") {
        return ComputerConnectionMonitorError;
    }
    if (name === "ComputerConnectionBrowserError") {
        return ComputerConnectionBrowserError;
    }
    return "";
};
