import axios, { AxiosInstance } from "axios";

export class CarModelService {
    private client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: "/api/vehicle/makes/ford/models",
        });
    }

    public async getCars(type: string): Promise<string[]> {
        return this.client.get<string[]>(type).then((result) => result.data);
    }
}

export const carModelService = new CarModelService();
