import {
    BalButton,
    BalHeading,
    BalModal,
    BalModalBody,
    BalModalHeader,
    BalText,
} from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import { differenceInYears } from "date-fns";
import React, { useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Formula36 } from "./DiscountBlock.enums";
import {
    calculateDiscount,
    createdDiscountExplanationI18nContext,
} from "./DiscountBlock.helpers";
import { DiscountBlockProps } from "./DiscountBlock.types";

import { CarType, MileageOptions } from "@modules/shared/enums";

/**
 * DiscountBlock
 */
const DiscountBlock: React.FC<DiscountBlockProps> = ({
    driversLicenseDate,
    bonusMalus,
    mileage,
    amountOfSafetyOptions,
    carType,
    carRegistrationDate,
    hasFormula36 = false,
}) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const modalRef = useRef<HTMLBalModalElement>(null);
    const { t } = useTranslation();
    const discountInfo = calculateDiscount(
        amountOfSafetyOptions,
        mileage,
        driversLicenseDate,
    );
    const discountExplanationI18nContext =
        createdDiscountExplanationI18nContext(
            discountInfo.mileageDiscount,
            discountInfo.safetyOptionsDiscount,
        );

    useEffect(() => {
        const currRef = modalRef.current;

        return () => {
            currRef?.close();
        };
    }, [modalRef]);

    const formula36 = useMemo(() => {
        if (!hasFormula36) {
            return Formula36.None;
        }

        if (carType === CarType.New) {
            return Formula36.NewCar;
        }

        if (
            (carType === CarType.Current || carType === CarType.SecondHand) &&
            carRegistrationDate &&
            differenceInYears(new Date(), new Date(carRegistrationDate)) < 1
        ) {
            return Formula36.CurrentCar;
        }

        return Formula36.None;
    }, [carType, carRegistrationDate, hasFormula36]);

    if (
        bonusMalus === -2 ||
        (mileage && mileage !== MileageOptions.Unlimited) ||
        amountOfSafetyOptions > 1 ||
        formula36 !== Formula36.None
    ) {
        return (
            <section className="bg-light-blue insurance-discounts">
                <div className="container py-normal">
                    <div className="grid">
                        <div className="col is-half">
                            <div className="flex justify-content-space-between">
                                <BalHeading subtitle level="h3">
                                    {t("simulator.insurances.discounts.title")}
                                </BalHeading>
                                {isMobile && (
                                    <BalButton
                                        onClick={() => modalRef.current?.open()}
                                        color="link"
                                        flat
                                        icon="info-circle"
                                        className="mt-xx-small mr-small"
                                    />
                                )}
                            </div>
                            <ul className="list mt-normal text-small text-grey">
                                {bonusMalus === -2 && (
                                    <li className="m-none">
                                        {t("simulator.insurances.bonusmalus")}
                                    </li>
                                )}

                                {discountInfo.totalDiscount > 0 && (
                                    <li className="m-none">
                                        {t(
                                            "simulator.insurances.discounts.discount",
                                            {
                                                percent:
                                                    discountInfo.totalDiscount,
                                            },
                                        )}
                                    </li>
                                )}

                                {formula36 !== Formula36.None && (
                                    <li className="m-none">
                                        <Trans
                                            i18nKey={`simulator.insurances.discounts.formula36.${formula36}`}
                                            components={{
                                                ul: <ul className="list" />,
                                                li: <li className="m-none" />,
                                            }}
                                        ></Trans>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col is-half text-align-right flex align-items-start justify-content-end">
                            {!isMobile && (
                                <BalButton
                                    onClick={() => modalRef.current?.open()}
                                    color="link"
                                    flat
                                    icon="info-circle"
                                    className=""
                                >
                                    {t(
                                        "simulator.insurances.discounts.helptext",
                                    )}
                                </BalButton>
                            )}
                        </div>
                    </div>
                </div>
                <BalModal
                    component={null}
                    ref={modalRef}
                    modalWidth={720}
                    isClosable={true}
                >
                    <BalModalHeader>
                        {t("simulator.insurances.discounts.title")}
                    </BalModalHeader>
                    <BalModalBody>
                        {bonusMalus === -2 && (
                            <>
                                <BalHeading subtitle level="h5">
                                    {t("simulator.insurances.bonusmalus")}
                                </BalHeading>
                                <BalText>
                                    {t(
                                        "simulator.insurances.bonusmalus.explanation",
                                    )}
                                </BalText>
                            </>
                        )}

                        {discountInfo.totalDiscount > 0 && (
                            <>
                                <BalHeading subtitle level="h5">
                                    {t(
                                        "simulator.insurances.discounts.discount",
                                        {
                                            percent: discountInfo.totalDiscount,
                                        },
                                    )}
                                </BalHeading>
                                <BalText>
                                    {t(
                                        "simulator.insurances.discounts.explanation",
                                        {
                                            context:
                                                discountExplanationI18nContext,
                                            mileage: discountInfo.mileageText,
                                            percent:
                                                discountInfo.mileageDiscount,
                                            amountOfSafetyOptions,
                                        },
                                    )}
                                </BalText>
                            </>
                        )}
                    </BalModalBody>
                </BalModal>
            </section>
        );
    } else {
        return <></>;
    }
};

export default DiscountBlock;
