import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Language } from "../../shared.enums";
import { PageInterface } from "../../shared.types";

export const { persistAtom } = recoilPersist({
    key: "ford-insurance-car",
    storage:
        typeof global?.window !== "undefined"
            ? global?.window?.sessionStorage
            : undefined,
});

export const appState = atom({
    key: "appState",
    default: {},
    effects_UNSTABLE: [persistAtom],
});

export const modalState = atom<string | undefined | null>({
    key: "modalState",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const currentLanguageState = atom<Language | null>({
    key: "currentLang",
    default: null,
});

export const currentPageState = atom<PageInterface | null>({
    key: "currentPage",
    default: null,
});
