import {
    BalAccordion,
    BalAccordionDetails,
    BalAccordionSummary,
    BalAccordionTrigger,
    BalContent,
    BalStack,
} from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import classNames from "classnames";
import React, { useState } from "react";

import * as style from "./ContactSegment.module.scss";
import { ContactItemProps } from "./ContactSegment.types";

import { FordContactModalContent } from "@modules/shared/state";

const ContactSegment: React.FC<FordContactModalContent> = ({
    iconLink,
    header,
    description,
    tel,
    email,
    fullSize,
}) => {
    const [accordionIsOpen, setAccordionIsOpen] = useState<boolean>(false);
    const isMobile = useMediaQuery("(max-width: 500px)");

    const ContactItem = ({ text, icon, action }: ContactItemProps) => {
        return (
            <div className="flex align-items-center">
                <img src={icon} alt="icon" />
                <button onClick={action} className={style.contactItem}>
                    {text}
                </button>
            </div>
        );
    };

    const HeadContent = () => {
        return <div className={style.title}>{header}</div>;
    };

    const BodyContent = () => {
        return (
            <>
                <div
                    className={style.children}
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />

                <div className={style.contactItemContainer}>
                    {email && (
                        <ContactItem
                            text={email}
                            icon="/Envelope.png"
                            action={() =>
                                window.open(`mailto:${email}`, "_self")
                            }
                        />
                    )}
                    {tel && (
                        <ContactItem
                            text={tel}
                            icon="/Phone.png"
                            action={() => window.open(`tel:${tel}`, "_self")}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        <div
            className={classNames(style.container, {
                [style.primary]: !fullSize || isMobile,
                [style.secondary]: fullSize && !isMobile,
                [style.primaryActive]: accordionIsOpen,
            })}
        >
            <img
                src={`${process.env.GATSBY_CMS_ASSETS_URL}${iconLink}`}
                alt="icon"
                className="mb-normal"
            />
            {isMobile ? (
                <BalAccordion
                    onBalChange={({ detail }) => setAccordionIsOpen(detail)}
                >
                    <BalAccordionSummary trigger>
                        <BalStack>
                            <BalContent>
                                <HeadContent />
                            </BalContent>
                            {isMobile && <BalAccordionTrigger />}
                        </BalStack>
                    </BalAccordionSummary>
                    <BalAccordionDetails>
                        <div>
                            <BodyContent />
                        </div>
                    </BalAccordionDetails>
                </BalAccordion>
            ) : (
                <>
                    <HeadContent />
                    <BodyContent />
                </>
            )}
        </div>
    );
};

export default ContactSegment;
