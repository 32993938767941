import {
    ReactPlugin,
    withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { globalHistory } from "@reach/router";
import React from "react";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.GATSBY_INSTRUMENTATION_KEY,
        connectionString: `InstrumentationKey=${process.env.GATSBY_INSTRUMENTATION_KEY};IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/`,
        // NOTE: Cast to any because of the following issue => https://github.com/microsoft/applicationinsights-react-js/issues/32
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extensions: [reactPlugin as any],
        extensionConfig: {
            [reactPlugin.identifier]: { history: globalHistory },
        },
        loggingLevelConsole: 2,
        enableDebug: true,
        enablePerfMgr: true,
        enableUnhandledPromiseRejectionTracking: true,
        accountId: "LG",
    },
});

appInsights.loadAppInsights();

const TrackingLoader: React.FC = () => {
    return <></>;
};

export default withAITracking(reactPlugin, TrackingLoader);
