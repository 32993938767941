import classNames from "classnames";
import React from "react";

import { SpinnerColor, SpinnerSize } from "./Spinner.enums";
import * as style from "./Spinner.module.scss";
import { SpinnerProps } from "./Spinner.types";

export const Spinner: React.FC<SpinnerProps> = ({
    color = SpinnerColor.Primary,
    size = SpinnerSize.Small,
}) => {
    const svgClassNames = classNames(style.cSpinner, {
        [style.cSpinnerSmall]: size === SpinnerSize.Small,
        [style.cSpinnerMedium]: size === SpinnerSize.Medium,
        [style.cSpinnerLarge]: size === SpinnerSize.Large,
        [style.cSpinnerXLarge]: size === SpinnerSize.XLarge,
    });

    const pathClassNames = classNames({
        [style.cSpinnerPrimary]: color === SpinnerColor.Primary,
    });

    return (
        <svg
            className={svgClassNames}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                className={pathClassNames}
                d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
            />
        </svg>
    );
};
