import {
    BalHeading,
    BalModal,
    BalModalBody,
    BalModalHeader,
} from "@baloise/ds-react";
import React, { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";

import ContactSegment from "./Components/ContactSegment/ContactSegment";
import { ProductContactModalProps } from "./ProductContactModal.types";

import { selectContactModal } from "@modules/shared/state";

const ProductContactModal: React.FC<ProductContactModalProps> = ({
    onClose,
}) => {
    const contactData = useRecoilValue(selectContactModal);
    const modalRef = useRef<HTMLBalModalElement>(null);

    useEffect(() => {
        const currRef = modalRef.current;
        modalRef?.current?.open();

        return () => {
            onClose();
            currRef?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BalModal
            ref={modalRef}
            component={null}
            isClosable={true}
            onBalModalDidDismiss={() => onClose()}
            modalWidth={850}
        >
            <BalModalHeader>
                <BalHeading level="h3" subtitle>
                    {contactData.title}
                </BalHeading>
            </BalModalHeader>
            <BalModalBody>
                <div className="grid is-multiline">
                    {contactData.items
                        .slice()
                        .sort((a, b) => a.order - b.order)
                        .map((block, index) => (
                            <div
                                key={index}
                                className={
                                    block.fullSize
                                        ? "col is-full"
                                        : "col is-half"
                                }
                            >
                                <ContactSegment {...block} />
                            </div>
                        ))}
                </div>
            </BalModalBody>
        </BalModal>
    );
};

export default ProductContactModal;
