import { createContext } from "react";

export type TabsContextType = {
    prevPagePath?: string;
    prevPageButtonText?: string;
    resetButtonText?: string;
};

export const TabsContext = createContext<TabsContextType | undefined>(
    undefined,
);
