import classNames from "classnames";
import React from "react";

import * as style from "./ImageText.module.scss";
import { ImageTextProps } from "./ImageText.types";

const SimulateBounty: React.FC<ImageTextProps> = ({
    image,
    className,
    children,
    reverseFlex,
    alt = "",
}) => {
    return (
        <section
            className={classNames(style.cImageText, className, {
                [style.cImageText__reverse]: reverseFlex,
            })}
        >
            <div
                className={classNames(
                    style.cImageText__text,
                    "bg-light-blue",
                    "flex",
                    "align-items-center",
                    {
                        [style.cImageText__textreverse]: reverseFlex,
                    },
                )}
            >
                {children}
            </div>
            <img
                className={style.cImageText__image}
                src={`${process.env.GATSBY_CMS_ASSETS_URL}${image}`}
                alt={alt}
            />
        </section>
    );
};

export default SimulateBounty;
