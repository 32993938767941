import { useEffect, useState } from "react";

import { useWindow } from "../Window";

export const useMediaQuery = (query: string) => {
    const { hasWindow } = useWindow();
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        if (hasWindow) {
            const media = global.window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => setMatches(media.matches);
            global.window.addEventListener("resize", listener);
            return () => global.window.removeEventListener("resize", listener);
        }
    }, [hasWindow, matches, query]);

    return matches;
};
