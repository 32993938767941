import { selector } from "recoil";

import { currentProductPageQuery } from "./productPage.query";

/**
 * Select the menu data from the product page
 */
export const selectMenu = selector({
    key: "productPage.menu",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return productPage?.fordMenus ?? [];
    },
});

/**
 * Select the hero data from the product page
 */
export const selectHero = selector({
    key: "productPage.hero",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.title ?? "",
            subTitle: productPage?.fordPage.subTitle ?? "",
            description: productPage?.fordPage.description ?? "",
            image: productPage?.fordPage.image ?? "",
            simButtonText: productPage?.fordPage.simButtonText ?? "",
            simButtonUrl: productPage?.fordPage.simButtonUrl ?? "",
        };
    },
});

/**
 * Select the USP'S data from the product page
 */
export const selectUSP = selector({
    key: "productPage.USP",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.uspTitle ?? "",
            usps: productPage?.fordUsps ?? [],
        };
    },
});

/**
 * Select the advisories data from the product page
 */
export const selectAdvisories = selector({
    key: "productPage.Advisories",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return (
            productPage?.fordAdvisorys.map((advisory) => ({
                question: advisory.title,
                answer: advisory.description,
            })) ?? []
        );
    },
});

/**
 * Select the insurance types info data from the product page
 */
export const selectInsuranceTypesInfo = selector({
    key: "productPage.insuranceTypesInfo",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        const {
            advisorTitle = "",
            productLabel1 = "",
            productLabel2 = "",
            productLabel3 = "",
            productSubLabel1 = "",
            productSubLabel2 = "",
            productSubLabel3 = "",
            productCategoryLabel1 = "",
            productCategoryLabel2 = "",
            productCategoryLabel3 = "",
            productCategoryLabel4 = "",
            productCategoryLabel5 = "",
            productCategoryLabel6 = "",
            productCategoryLabel7 = "",
        } = productPage?.fordPage ?? {};

        return {
            advisorTitle,
            productLabel1,
            productLabel2,
            productLabel3,
            productSubLabel1,
            productSubLabel2,
            productSubLabel3,
            productCategoryLabel1,
            productCategoryLabel2,
            productCategoryLabel3,
            productCategoryLabel4,
            productCategoryLabel5,
            productCategoryLabel6,
            productCategoryLabel7,
        };
    },
});

/**
 * Select the simulate bounty data form the product page
 */
export const selectSimulateBounty = selector({
    key: "productPage.simulateBounty",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.simTitle ?? "",
            buttonUrl: productPage?.fordPage.simButtonUrl ?? "",
            buttonText: productPage?.fordPage.simButtonText ?? "",
            image: productPage?.fordPage.simAsset ?? "",
        };
    },
});

/**
 * Select additional insurances data from the product page
 */
export const selectAdditionalInsurances = selector({
    key: "productPage.additionalInsurances",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.eServiceTitle ?? "",
            subTitle: productPage?.fordPage.eServiceSubTitle ?? "",
            services: productPage?.fordExtraServices ?? [],
            image: productPage?.fordPage.imageField ?? "",
        };
    },
});

/**
 * Select road to's data from the product page
 */
export const selectRoadTo = selector({
    key: "productPage.roadTo",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.roadToTitle ?? "",
            subTitle: productPage?.fordPage.roadToSubTitle ?? "",
            ctaTitle: productPage?.fordPage.roadToCallToActionTitle ?? "",
            ctaUrl: productPage?.fordPage.roadToCallToActionUrl ?? "",
            roadTos: productPage?.fordRoadTos ?? [],
        };
    },
});

/**
 * Select contact data from the product page
 */
export const selectContact = selector({
    key: "productPage.contact",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.contactTitle ?? "",
            description: productPage?.fordPage.contactDescription ?? "",
            image: productPage?.fordPage.contactImage ?? "",
        };
    },
});

/**
 * Select FAQ data from the product page
 */
export const selectFaq = selector({
    key: "productPage.faq",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.faqTitle,
            faqCategories: productPage?.fordFaqCategories ?? [],
        };
    },
});

/**
 * Select documents data from the product page
 */
export const selectDocuments = selector({
    key: "productPage.documents",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage.documentsTitle ?? "",
            documents: productPage?.fordDocuments ?? [],
        };
    },
});

/**
 * Select legal data from the product page
 */
export const selectLegal = selector({
    key: "productPage.legal",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return [
            {
                title: productPage?.fordPage.infoTitle ?? "",
                subTitle: productPage?.fordPage.infoSubTitle ?? "",
                description: productPage?.fordPage.infoDescription ?? "",
                anchor: "product-page.anchor.legal",
            },
            {
                title: productPage?.fordPage.infoAboutTitle ?? "",
                description: productPage?.fordPage.infoAboutDescription ?? "",
                anchor: "product-page.anchor.legal",
            },
            {
                title: productPage?.fordPage.complainTitle ?? "",
                description: productPage?.fordPage.complainDescription ?? "",
                anchor: "product-page.anchor.complaints",
            },
        ];
    },
});

export const selectFooter = selector({
    key: "productPage.footer",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return productPage?.fordFooters ?? [];
    },
});

export const selectContactModal = selector({
    key: "productPage.contactModal",
    get: ({ get }) => {
        const productPage = get(currentProductPageQuery);

        return {
            title: productPage?.fordPage?.contactModalTitle ?? "",
            items: productPage?.fordContactModalContents ?? [],
        };
    },
});
