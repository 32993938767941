import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import {
    Help,
    LanguageSwitch,
    NavBar,
    PageContainer,
    SEO,
} from "../../components";

import { PageLayoutProps } from "./PageLayout.types";

import QuestionCircle from "@images/icons/question-circle.svg";
import { ModalContext } from "@modules/shared/context";
import { ModalName } from "@modules/shared/enums";
import { modalState } from "@modules/shared/state";

const PageLayout: React.FC<PageLayoutProps> = ({
    language,
    children,
    page,
    headerTitle,
    seoTitle,
    hideHelp = false,
}) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const [modal] = useRecoilState(modalState);
    const setModal = useSetRecoilState(modalState);

    return (
        <>
            <SEO title={seoTitle} language={language} />
            <ModalContext.Provider value={modal}>
                <PageContainer>
                    <NavBar
                        left={<>{!isMobile && <>{headerTitle}</>}</>}
                        right={
                            <>
                                <LanguageSwitch
                                    page={page}
                                    language={language}
                                    prefix={process.env.GATSBY_PATH_PREFIX_FORD}
                                />
                                <div className="ml-small mr-normal">|</div>
                                {!hideHelp && (
                                    <Help
                                        onHelpClick={() =>
                                            setModal(ModalName.Contact)
                                        }
                                        icon={QuestionCircle}
                                    />
                                )}
                            </>
                        }
                    />
                    {children}
                </PageContainer>
            </ModalContext.Provider>
        </>
    );
};

export default PageLayout;
