import {
    balIconAlertTriangle,
    balIconArrowsRoundLeft,
    balIconBack,
    balIconCall,
    balIconCaretLeft,
    balIconCaretRight,
    balIconCheckCircle,
    balIconContact,
    balIconDownload,
    balIconInfoCircle,
    balIconRefresh,
} from "@baloise/design-system-icons";
import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
    useBaloiseDesignSystem,
} from "@baloise/ds-react";
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { FeatureFlags, Language } from "../../shared.enums";

import { Loading, NavBar, PageContainer } from "@modules/shared/components";
import {
    currentLanguageState,
    currentPageState,
    currentProductPageQuery,
} from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const RootLayout: React.FC<PageProps> = ({ children, pageContext }) => {
    const { language, foundPage: page } = pageContext;
    const setCurrentPage = useSetRecoilState(currentPageState);
    const setCurrentLang = useSetRecoilState(currentLanguageState);
    const dataLoadable = useRecoilValueLoadable(currentProductPageQuery);
    const { t } = useTranslation();
    const gb = useGrowthBook();

    useBaloiseDesignSystem({
        defaults: {
            icons: {
                balIconArrowsRoundLeft,
                balIconCheckCircle,
                balIconAlertTriangle,
                balIconContact,
                balIconCaretRight,
                balIconCaretLeft,
                balIconCall,
                balIconBack,
                balIconRefresh,
                balIconInfoCircle,
                balIconDownload,
            },
            region: "BE",
            language: language === Language.NL ? Language.NL : Language.FR,
        },
    });

    /**
     * Set the current active page
     * Each component inside the current page can now access the current page
     */
    useEffect(() => {
        setCurrentPage(page);
    }, [page, setCurrentPage]);

    /**
     * Set the current language
     */
    useEffect(() => {
        setCurrentLang(language);
    }, [language, setCurrentLang]);

    /**
     * Refetch the growthbook feature toggles when the
     * current page has changed
     */
    useEffect(() => {
        gb?.refreshFeatures();
    }, [page, gb]);

    const refreshCurrentPage = () => {
        window.location.href = page.paths[language];
    };

    const isInMaintenanceMode = useFeatureIsOn(
        FeatureFlags.MaintenanceMode as string,
    );

    if (isInMaintenanceMode) {
        return (
            <PageContainer>
                <NavBar left={<>{t("maintenance.page.title")}</>}></NavBar>

                <div className="flex flex-direction-column align-items-center flex-1 justify-content-center">
                    <BalCard flat>
                        <BalCardContent className="px-xxx-large py-x-large">
                            <div className="flex flex-direction-column align-items-center text-align-center">
                                <svg
                                    className="mb-medium"
                                    width="66"
                                    height="66"
                                    viewBox="0 0 66 66"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.7876 1.57617L23.7876 11.5762C24.5376 12.2012 25.0376 13.0762 25.0376 13.9512V20.7012L38.6626 34.3262C42.2876 32.5762 46.7876 33.2012 49.7876 36.2012L63.7876 50.2012C65.4126 51.7012 65.4126 54.3262 63.7876 55.8262L55.7876 63.8262C54.2876 65.4512 51.6626 65.4512 50.1626 63.8262L36.1626 49.8262C33.1626 46.8262 32.5376 42.2012 34.4126 38.5762L20.7876 24.9512H13.9126C13.0376 24.9512 12.1626 24.5762 11.5376 23.8262L1.5376 10.8262C0.662598 9.57617 0.787598 7.95117 1.9126 6.82617L6.9126 1.82617C7.9126 0.826172 9.6626 0.701172 10.7876 1.57617ZM3.9126 48.0762L21.0376 30.9512L25.2876 35.2012L8.1626 52.3262C7.4126 53.0762 7.0376 54.0762 7.0376 55.0762C7.0376 57.2012 8.7876 58.9512 10.9126 58.9512C11.9126 58.9512 12.9126 58.5762 13.6626 57.8262L29.2876 42.2012C29.1626 44.5762 29.6626 46.9512 30.7876 49.2012L17.9126 62.0762C16.0376 63.9512 13.5376 64.9512 10.9126 64.9512C5.4126 64.9512 1.0376 60.5762 1.0376 55.0762C1.0376 52.4512 2.0376 49.9512 3.9126 48.0762ZM58.9126 21.4512L55.7876 24.7012C54.2876 26.2012 52.2876 26.9512 50.1626 26.9512H47.0376C42.5376 26.9512 39.0376 23.4512 39.0376 18.9512V15.8262C39.0376 13.7012 39.7876 11.7012 41.2876 10.2012L44.5376 7.07617C37.0376 7.32617 31.0376 13.4512 31.0376 20.9512V21.0762L29.0376 19.0762V13.9512C29.0376 12.7012 28.6626 11.4512 28.0376 10.4512C31.5376 4.82617 37.7876 0.951172 45.0376 0.951172C48.0376 0.951172 51.0376 1.70117 53.6626 2.95117C54.7876 3.57617 54.9126 5.07617 54.0376 5.95117L45.5376 14.4512C45.1626 14.8262 45.0376 15.3262 45.0376 15.8262V19.0762C45.0376 20.0762 45.9126 21.0762 47.0376 21.0762H50.1626C50.6626 21.0762 51.1626 20.8262 51.5376 20.4512L60.0376 11.9512C60.9126 11.0762 62.4126 11.2012 63.0376 12.3262C64.2876 14.9512 65.0376 17.9512 65.0376 21.0762C65.0376 27.7012 61.6626 33.7012 56.5376 37.3262L52.6626 33.3262C52.5376 33.2012 52.4126 33.0762 52.2876 32.9512C56.1626 30.5762 58.7876 26.3262 58.9126 21.4512Z"
                                        fill="#102B4E"
                                    />
                                </svg>

                                <BalHeading
                                    level="h1"
                                    space="none"
                                    className="mb-normal"
                                >
                                    <span className="font-weight-light">
                                        {t("maintenance.title")}
                                    </span>
                                </BalHeading>
                                <BalText space="none" className="mb-medium">
                                    {t("maintenance.text")}
                                </BalText>

                                <BalButton
                                    rounded
                                    color="info"
                                    icon="refresh"
                                    onClick={refreshCurrentPage}
                                >
                                    {t("maintenance.button.text")}
                                </BalButton>
                            </div>
                        </BalCardContent>
                    </BalCard>
                </div>
            </PageContainer>
        );
    }

    const renderLoadable = (): ReactElement | null => {
        switch (dataLoadable.state) {
            case "hasValue":
                return <>{children}</>;
            case "loading":
            case "hasError":
                return <Loading></Loading>;
        }
    };

    return <>{renderLoadable()}</>;
};

export default RootLayout;
