import { selectorFamily } from "recoil";

import { engineVariantService } from "./engineVariant.service";

export const engineVariantsQuery = selectorFamily({
    key: "engineVariants",
    get:
        ({ model, type }: { model: string; type: string }) =>
        () => {
            if (model && type) {
                return engineVariantService.getEngineVariants(model, type);
            }
        },
});
