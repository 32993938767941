import { BalHeading } from "@baloise/ds-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import { modalState } from "../../state/app";

import { InsuranceContactBlockProps } from "./InsuranceContactBlock.types";

import { ModalName } from "@modules/shared/enums";

export const InsuranceContactBlock = ({
    title,
    description,
}: InsuranceContactBlockProps) => {
    const { t } = useTranslation();
    const setModal = useSetRecoilState(modalState);

    return (
        <section className="text-align-center mb-large">
            <BalHeading level="h5">{t(title ?? "")}</BalHeading>
            <div className="flex align-content-center justify-content-center">
                <Trans
                    t={t}
                    i18nKey={description}
                    components={{
                        contact: (
                            <a
                                onClick={() => setModal(ModalName.Contact)}
                                className="link ml-xx-small"
                            />
                        ),
                    }}
                />
            </div>
        </section>
    );
};

export default InsuranceContactBlock;
