import { BalIcon, BalText } from "@baloise/ds-react";
import React from "react";
import { useTranslation } from "react-i18next";

import { LoadingProps } from "./Loading.types";

/**
 * Loading
 */
const Loading: React.FC<LoadingProps> = ({
    translationKey = "loading.regular.text",
}) => {
    const { t } = useTranslation();

    return (
        <div className="loading-screen flex justify-content-center align-items-center">
            <div className="loading-screen__text flex flex-direction-column align-items-center">
                <BalIcon color="white" name="refresh" size="large" />
                <BalText className="mt-normal">{t(translationKey)}</BalText>
            </div>
        </div>
    );
};

export default Loading;
