export enum DiscountExplanationI18nContext {
    MileageSafetyOptions = "mileage_safetyoptions",
    Mileage = "mileage",
    SafetyOptions = "safetyoptions",
}

export enum Formula36 {
    None = "none",
    NewCar = "newCar",
    CurrentCar = "currentCar",
}
