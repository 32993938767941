import { BalSpinner } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";

import { TableProps } from "./Table.types";
import { TableCell } from "./TableCell";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

const Table: React.FC<TableProps> = ({
    columns,
    data,
    loading,
    tableClassName,
    tableRowClassName,
    tableCellClassName,
    tableHeaderClassName,
}) => {
    // Render
    const renderLoader = () => <BalSpinner />;

    const renderTableRow = (row: Record<string, unknown>, rowIndex: number) => (
        <TableRow className={tableRowClassName} key={`table-row-${rowIndex}`}>
            {columns.map((col, colIndex) => (
                <TableCell
                    key={`table-cell-${colIndex}`}
                    className={tableCellClassName}
                    component={col.component}
                    rowData={row}
                    value={row[col.key]}
                />
            ))}
        </TableRow>
    );

    return (
        <table className={classNames("table w-full", tableClassName)}>
            {columns.length > 0 && (
                <thead>
                    <TableRow>
                        {columns.map((col, index) => (
                            <TableHeader
                                key={`table-header-${col.label}-${index}`}
                                className={tableHeaderClassName}
                                component={col.headerComponent}
                                hideLabel={col.hideLabel}
                                label={col.label}
                            />
                        ))}
                    </TableRow>
                </thead>
            )}

            {
                <tbody>
                    {loading
                        ? renderLoader()
                        : data.map((data, index) =>
                              renderTableRow(data, index),
                          )}
                </tbody>
            }
        </table>
    );
};
export default Table;
