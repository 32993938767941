import {
    ColumnSize,
    FieldDataType,
    FieldDisplay,
    FieldSchema,
    FieldType,
} from "@baloise-cfa/form-renderer-frontend";

import { Application, FordFieldType } from "../../shared.enums";

export const createFields = (application?: Application): FieldSchema[] => [
    {
        name: "firstname",
        type: FieldType.Text,
        label: "contactForm.firstName",
        testClass: "ford-firstname",
        config: {
            wrapperColumnSize: ColumnSize.Half,
        },
        validators: {
            required: {
                message: "contactForm.firstName.required.message",
            },
        },
    },
    {
        name: "lastname",
        type: FieldType.Text,
        label: "contactForm.lastName",
        testClass: "ford-lastname",
        config: {
            wrapperColumnSize: ColumnSize.Half,
        },
        validators: {
            required: {
                message: "contactForm.lastName.required.message",
            },
        },
    },
    {
        name: "companyname",
        type: FieldType.Text,
        label: "contactForm.companyName",
        testClass: "ford-companyname",
        config: {
            fieldColumnSize: ColumnSize.Half,
        },
        validators: {
            required: {
                message: "contactForm.companyName.required.message",
            },
        },
        conditionals: {
            display: FieldDisplay.Show,
            fields: {
                "context.professionalUsage": {
                    operator: "===",
                    value: true,
                },
            },
        },
    },
    {
        name: "phone",
        type: FieldType.Text,
        label: "contactForm.phone",
        testClass: "ford-phone",
        config: {
            fieldColumnSize: ColumnSize.OneThird,
        },
        validators: {
            required: {
                message: "contactForm.phone.required.message",
            },
            matches: {
                value: /^\+?[0-9]{9,15}$/,
                message: "contactForm.phone.required.message",
            },
        },
    },
    {
        name: "email",
        type: FieldType.Text,
        label: "contactForm.email",
        testClass: "ford-email",
        config: {
            fieldColumnSize: ColumnSize.Half,
        },
        validators: {
            required: {
                message: "contactForm.email.required.message",
            },
            email: {
                message: "contactForm.email.required.message",
            },
        },
    },
    {
        name: "remark",
        type: FieldType.TextArea,
        label: "contactForm.remark",
        testClass: "ford-remark",
    },
    {
        name: "consent",
        type: FordFieldType.Consent,
        dataType: FieldDataType.Boolean,
        errorLabel: "contactForm.consent.errorLabel",
        label:
            application === Application.Dealer
                ? "contactForm.consent.dealer"
                : "contactForm.consent",
        testClass: "ford-consent",
        validators: {
            required: {
                message: "contactForm.consent.required.message",
            },
            oneOf: {
                value: [true],
                message: "contactForm.consent.required.message",
            },
        },
    },
];
