import { BalAccordionCustomEvent } from "@baloise/ds-core";
import {
    BalAccordion,
    BalAccordionDetails,
    BalAccordionSummary,
    BalAccordionTrigger,
    BalContent,
    BalLabel,
    BalStack,
    BalText,
} from "@baloise/ds-react";
import classNames from "classnames";
import React, { useState } from "react";

import * as style from "./AccordionList.module.scss";
import { AccordionListProps } from "./AccordionList.types";

const AccordionList: React.FC<AccordionListProps> = ({ data, className }) => {
    const [accordionState, setAccordionState] = useState<
        Record<string, boolean>
    >({});

    const handleBalChange = (
        event: BalAccordionCustomEvent<boolean>,
        key: string,
    ) => {
        const updateData = event.detail
            ? {
                  [key]: true,
              }
            : {};

        setAccordionState(updateData);
    };

    return (
        <div className={className}>
            {data.map((item, index) => {
                const key = `accordion-item-${index}`;

                return (
                    <BalAccordion
                        key={key}
                        className={classNames(style.accordionList__item, {
                            [style.accordionList__itemActive]:
                                !!accordionState[key],
                        })}
                        onBalChange={(isOpen) => handleBalChange(isOpen, key)}
                        active={!!accordionState[key]}
                    >
                        <BalAccordionSummary trigger>
                            <BalStack className="py-small px-medium">
                                <BalContent>
                                    <BalLabel>
                                        <span
                                            className={
                                                style.accordionList__itemLabel
                                            }
                                        >
                                            {item.label}
                                        </span>
                                    </BalLabel>
                                </BalContent>
                                <BalAccordionTrigger />
                            </BalStack>
                        </BalAccordionSummary>
                        <BalAccordionDetails>
                            {typeof item.content === "function" ? (
                                item.content(index)
                            ) : (
                                <BalText
                                    size="small"
                                    className="px-medium"
                                    color="grey"
                                >
                                    <div
                                        className={
                                            style.accordionList__itemContent
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: item.content,
                                        }}
                                    ></div>
                                </BalText>
                            )}
                        </BalAccordionDetails>
                    </BalAccordion>
                );
            })}
        </div>
    );
};

export default AccordionList;
