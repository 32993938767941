import { BalButton, BalHeading, BalIcon, BalText } from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery, SideNavStatus } from "@lg-cfa/interfaces-enums";
import { useLocation } from "@reach/router";
import classnames from "classnames";
import { navigate } from "gatsby";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import { useSidebarCalculations } from "../../hooks/useSidebarCalculations";
import { ResetButton } from "../ResetButton";

import * as style from "./SideNavigation.module.scss";
import { HeadingProps, SideNavigationProps } from "./SideNavigation.types";

import { TabsContext } from "@modules/shared/context";
import { modalState } from "@modules/shared/state";

const Heading: React.FC<HeadingProps> = ({ title, description }) => {
    const { t } = useTranslation();

    return (
        <>
            {title && (
                <BalHeading space="none" level="h5" subtitle>
                    {t(`${title}`)}
                </BalHeading>
            )}
            {description && (
                <BalText size="small" color="grey">
                    {t(`${description}`)}
                </BalText>
            )}
        </>
    );
};

const SideNavigation: React.FC<SideNavigationProps> = ({
    title,
    description,
    tabs,
    language,
    tabsStatus,
}) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const { sideNavigationStyle } = useSidebarCalculations();
    const scrollRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const location = useLocation();
    const setModal = useSetRecoilState(modalState);
    const tabsContext = useContext(TabsContext);

    useEffect(() => {
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                    inline: "center",
                    block: "end",
                });
            }
        }, 100);
    }, []);

    return (
        <>
            {isMobile && (
                <div className="px-medium py-large bg-white">
                    <Heading title={title} description={description} />
                </div>
            )}
            <div
                style={sideNavigationStyle}
                className={classnames("flex align-items-center bg-white", {
                    "pt-x-large pb-medium flex-direction-column": !isMobile,
                    "px-medium flex-direction-row": isMobile,
                })}
            >
                {!isMobile && (
                    <div className="px-medium">
                        <Heading title={title} description={description} />
                    </div>
                )}
                {tabs.map((tab) => {
                    const isActive = (
                        location.pathname + location.search
                    ).includes(tab.paths[language]);

                    const tabStatus = tabsStatus[tab.name];

                    return (
                        <div
                            ref={isActive ? scrollRef : null}
                            key={tab.name}
                            className={classnames(
                                "cursor-pointer flex justify-content-space-between align-items-center",
                                {
                                    "bg-grey-1": isActive,
                                    "w-full px-medium py-small": !isMobile,
                                    "px-normal py-normal flex-shrink-0 radius-large":
                                        isMobile,
                                },
                            )}
                            onClick={() => navigate(tab.paths[language])}
                        >
                            <div>
                                <BalText
                                    space="none"
                                    bold={isActive}
                                    className={style.title}
                                >
                                    {tab.title}
                                </BalText>
                                {!isMobile && tab?.description && (
                                    <div className={style.name}>
                                        {t(tab.description)}
                                    </div>
                                )}
                            </div>
                            <div
                                className={classnames(
                                    "flex",
                                    { "ml-small": isMobile },
                                    { "ml-xx-small": !isMobile },
                                )}
                            >
                                {tabStatus && (
                                    <BalIcon
                                        name={
                                            tabStatus.isActive
                                                ? SideNavStatus.EDIT
                                                : tabStatus.isValid
                                                ? SideNavStatus.DONE
                                                : SideNavStatus.ERROR
                                        }
                                    />
                                )}

                                {!isMobile && (
                                    <BalIcon
                                        className="ml-normal"
                                        color="primary"
                                        name="nav-go-right"
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
                {!isMobile &&
                    tabsContext?.prevPagePath &&
                    tabsContext?.prevPageButtonText && (
                        <BalButton
                            className="mb-none mt-auto"
                            color="link"
                            rounded
                            icon="nav-go-left"
                            onClick={() =>
                                navigate(tabsContext?.prevPagePath ?? "")
                            }
                        >
                            {t(tabsContext?.prevPageButtonText)}
                        </BalButton>
                    )}
                {!isMobile && tabsContext?.resetButtonText && (
                    <ResetButton setModal={setModal}>
                        {t(tabsContext.resetButtonText)}
                    </ResetButton>
                )}
            </div>
        </>
    );
};

export default SideNavigation;
