export enum SpinnerSize {
    Small = "sm",
    Medium = "md",
    Large = "lg",
    XLarge = "x-lg",
}

export enum SpinnerColor {
    Primary = "primary",
}
