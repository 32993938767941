import { BalHeading, BalText } from "@baloise/ds-react";
import { formatPrice } from "@lg-cfa/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SummaryProps } from "./Summary.types";

/**
 * Summary
 */
const Summary: React.FC<SummaryProps> = ({
    chosenInsurance,
    chosenOptions,
    children,
}) => {
    const { t } = useTranslation();
    const [price, setPrice] = useState<number>(0);

    useEffect(() => {
        let calculatedPrice = 0;
        if (chosenInsurance?.price) {
            calculatedPrice += chosenInsurance.price;
        }
        if (chosenOptions?.length) {
            chosenOptions.forEach((option) => {
                if (option.price) {
                    calculatedPrice += option.price;
                }
            });
        }
        setPrice(calculatedPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenInsurance, chosenOptions]);

    return (
        <section className="bg-primary insurance-summary">
            <div className="container pt-normal">
                <div className="grid">
                    <div className="col is-half">
                        <BalHeading subtitle color="white" level="h2">
                            {t("simulator.summary.title")}
                        </BalHeading>
                        {!chosenOptions?.length && (
                            <BalText color="white">
                                {t("simulator.summary.text")}
                            </BalText>
                        )}
                        {!!chosenOptions?.length && (
                            <ul className="list text-white">
                                {chosenOptions?.map(
                                    (option, $index: number) => (
                                        <li key={$index} className="mb-none">
                                            {t(
                                                `insurance.extraOptions.${option.type?.toLowerCase()}`,
                                            )}
                                        </li>
                                    ),
                                )}
                            </ul>
                        )}
                    </div>
                    <div className="col is-half text-align-right">
                        {price && (
                            <BalHeading color="white" level="h1">
                                {formatPrice.format(price)}
                            </BalHeading>
                        )}
                    </div>
                </div>
                {children}
            </div>
        </section>
    );
};

export default Summary;
