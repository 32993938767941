import { BalText } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import * as style from "./InfoBlock.module.scss";
import { InfoBlockProps } from "./InfoBlock.types";

const InfoBlock: React.FC<InfoBlockProps> = ({
    SVGElement,
    title,
    description,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classNames(style.infoBlock, "p-large")}>
            {SVGElement}
            <BalText
                className="mt-x-small mb-none text-align-center"
                size="small"
            >
                {t(title)}
            </BalText>
            {description ? (
                <BalText
                    color="grey"
                    className="text-align-center"
                    size="small"
                    space="none"
                >
                    {`(${t(description)})`}
                </BalText>
            ) : null}
        </div>
    );
};

export default InfoBlock;
