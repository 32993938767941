import { TFunction } from "i18next";
import { selectorFamily } from "recoil";

import { getDriverInfo } from "../../helpers/dto/dto.helpers";
import { Application, Language } from "../../shared.enums";

import { appState } from "./app.atom";

export const selectAddressString = (t: TFunction) =>
    selectorFamily<string | undefined, Language>({
        key: "appState.address",
        get:
            (language) =>
            ({ get }) => {
                const appData = get(appState);
                const driverInfo = getDriverInfo(
                    appData,
                    Application.Simulator,
                    language,
                );
                if (!driverInfo?.address) {
                    return;
                }
                const { bus, street, houseNumber, postalCode, city } =
                    driverInfo.address;
                return bus
                    ? ((t as unknown as TFunction)("all.full.address", {
                          bus,
                          street,
                          houseNumber,
                          postalCode,
                          city,
                      }) as string)
                    : `${street} ${houseNumber}, ${postalCode} ${city}`;
            },
    });
