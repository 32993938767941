import { BalButton } from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useContext } from "react";
import { useSetRecoilState } from "recoil";

import { NavigationProps } from "./Navigation.types";

import { ResetButton } from "@modules/shared/components";
import { TabsContext } from "@modules/shared/context";
import { modalState } from "@modules/shared/state";

const Navigation: React.FC<NavigationProps> = ({
    language,
    nextPage,
    nextPageBtnText,
    prevPage,
    prevPageBtnText,
    t,
    inverted = false,
    prevColor = "info",
    prevIcon = "caret-left",
    nextColor = "primary",
    nextIcon = "caret-right",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onNextClick = () => {},
}) => {
    const isMobile = useMediaQuery(MediaQuery.Mobile);
    const setModal = useSetRecoilState(modalState);
    const tabsContext = useContext(TabsContext);

    return (
        <section
            className={classNames("flex gap-medium", {
                "flex-direction-column-reverse align-items-center gap-x-large":
                    isMobile,
            })}
        >
            {isMobile &&
                !prevPage &&
                tabsContext?.prevPagePath &&
                tabsContext?.prevPageButtonText && (
                    <BalButton
                        className="mb-none mt-auto"
                        color="link"
                        rounded
                        icon="nav-go-left"
                        onClick={() =>
                            navigate(tabsContext?.prevPagePath ?? "")
                        }
                    >
                        {t(tabsContext?.prevPageButtonText)}
                    </BalButton>
                )}
            {isMobile && tabsContext?.resetButtonText && (
                <ResetButton setModal={setModal}>
                    {t(tabsContext?.resetButtonText)}
                </ResetButton>
            )}
            {prevPage && (
                <BalButton
                    expanded={isMobile}
                    rounded
                    inverted={inverted}
                    color={prevColor}
                    icon={prevIcon}
                    onClick={() => navigate(prevPage?.paths[language])}
                >
                    {prevPageBtnText
                        ? t(prevPageBtnText)
                        : t(prevPage?.navigation?.title ?? "all.previous")}
                </BalButton>
            )}
            {(nextPage || nextPageBtnText) && (
                <BalButton
                    rounded
                    inverted={inverted}
                    expanded={isMobile}
                    iconRight={nextIcon}
                    elementType="submit"
                    onClick={onNextClick}
                    color={nextColor}
                >
                    {nextPageBtnText
                        ? t(nextPageBtnText)
                        : t(nextPage?.navigation?.title ?? "all.next")}
                </BalButton>
            )}
        </section>
    );
};

export default Navigation;
