import React from "react";

import { TableCellProps } from "./TableCell.types";

const TableCell: React.FC<TableCellProps> = ({
    component,
    rowData,
    value,
    className,
}) => {
    return (
        <td className={className}>
            {component ? component(value, rowData) : value}
        </td>
    );
};
export default TableCell;
