export enum SummaryCardAppearance {
    Light = "Light",
    Dark = "Dark",
}

export enum SummaryCardTheme {
    Primary = "Primary",
    secondary = "Secondary",
}

export enum SummaryCardGridDirection {
    Row = "ROW",
    Col = "COL",
}
