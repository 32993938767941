import { BalButton, BalHeading, BalModal, BalText } from "@baloise/ds-react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ResetModalProps } from "./ResetModal.types";

const ResetModal: React.FC<ResetModalProps> = ({
    resetFlow,
    setModal,
    localePrefix,
}) => {
    const modalRef = useRef<HTMLBalModalElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const currRef = modalRef.current;
        modalRef?.current?.open();

        return () => {
            setModal("");
            currRef?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalRef]);

    return (
        <BalModal
            ref={modalRef}
            component={null}
            isClosable={true}
            style={{ zIndex: 3 }}
            onBalModalDidDismiss={() => setModal("")}
            className="reset-modal"
        >
            <BalHeading level="h4" subtitle>
                {t(`${localePrefix}.tabs.resetflow.popup.title`)}
            </BalHeading>
            <BalText>{t(`${localePrefix}.tabs.resetflow.popup.text`)}</BalText>
            <div className="flex justify-content-end">
                <BalButton
                    color="link"
                    className="mr-normal"
                    rounded
                    onClick={() => {
                        modalRef.current?.close();
                        setModal("");
                    }}
                >
                    {t("all.cancel")}
                </BalButton>
                <BalButton
                    color="danger"
                    rounded
                    icon="arrows-round-left"
                    onClick={() => resetFlow()}
                >
                    {t(`${localePrefix}.tabs.resetflow.popup.buttons.confirm`)}
                </BalButton>
            </div>
        </BalModal>
    );
};

export default ResetModal;
