import { BalLogo, BalText } from "@baloise/ds-react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import * as style from "./header.module.scss";
//TODO: Can be transferred to separate component package

type HeaderProps = {
    logo?: string;
    headerClass?: string;
};

/**
 * Header
 */
const Header: React.FC<HeaderProps> = ({ logo, headerClass }) => {
    const { t } = useTranslation();

    return (
        <header className={`bg-primary ${headerClass} ${style.header}`}>
            <div className={`container ${style.navbar}`}>
                <div>
                    {logo ? (
                        <img src={logo} style={{ maxHeight: "32px" }} />
                    ) : (
                        <BalLogo color="white" />
                    )}
                </div>
                <div className={style.navbarcontent}>
                    <BalText color="white" space="none">
                        <>{t("header.title")}</>
                    </BalText>
                </div>
                {/* <BalButtonGroup>
                    <LanguageSwitcher />
                </BalButtonGroup> */}
            </div>
        </header>
    );
};

export default Header;
