import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import React, { useEffect } from "react";

const growthbook = new GrowthBook({
    apiHost: process.env.GATSBY_GB_HOST,
    clientKey: process.env.GATSBY_GB_KEY,
    enableDevMode: true,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
        // TODO: Use your real analytics tracking system
        console.log("Viewed Experiment", {
            experimentId: experiment.key,
            variationId: result.key,
        });
    },
});

const ToggleWrapper: React.FC = ({ children }) => {
    useEffect(() => {
        // Load feature definitions from API
        growthbook.loadFeatures({ autoRefresh: true });
    }, []);

    return (
        <GrowthBookProvider growthbook={growthbook}>
            {children}
        </GrowthBookProvider>
    );
};

export default ToggleWrapper;
