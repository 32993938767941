import {
    BalButton,
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import ContactForm from "../ContactForm/ContactForm";

import { composeFirstParagraph } from "./WtcContactMe.helpers";
import { WtcContactMeProps } from "./WtcContactMe.types";

const WtcContactMe: React.FC<WtcContactMeProps> = ({
    wtcReasons,
    application,
    initialValue = {},
    onSubmit,
    loading = false,
    professionalUsage,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(MediaQuery.Mobile);

    return (
        <>
            <div className="intro">
                <BalHeading level="h1">
                    {t("all.wtccontactme.title")}
                </BalHeading>
                <BalText>{composeFirstParagraph(wtcReasons, t)}</BalText>
                <BalText>{t("all.wtccontactme.paragraph2")}</BalText>
                <BalText space="none">
                    {t("all.wtccontactme.paragraph3")}
                </BalText>
            </div>
            <BalCard>
                <BalCardContent>
                    <ContactForm
                        initialValue={initialValue}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        application={application}
                        professionalUsage={professionalUsage}
                    >
                        <section
                            className={classnames("mt-large flex gap-medium", {
                                "flex-direction-column": isMobile,
                            })}
                        >
                            <BalButton
                                rounded
                                expanded={isMobile}
                                color="primary"
                                elementType="submit"
                                disabled={loading}
                            >
                                {t(`all.wtccontactme.action`)}
                            </BalButton>
                        </section>
                    </ContactForm>
                </BalCardContent>
            </BalCard>
        </>
    );
};

export default WtcContactMe;
