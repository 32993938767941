// extracted by mini-css-extract-plugin
export var card = "SummaryCard-module--card--kTlYk";
export var cardPrimary = "SummaryCard-module--cardPrimary--O414C";
export var editButton = "SummaryCard-module--editButton--WsnXi";
export var headerWrapper = "SummaryCard-module--headerWrapper--Cn3Db";
export var isCol = "SummaryCard-module--isCol--z3REE";
export var isRow = "SummaryCard-module--isRow--WgiMS";
export var liWrapper = "SummaryCard-module--liWrapper--IDzmM";
export var primaryTitle = "SummaryCard-module--primaryTitle--YtLZv";
export var title = "SummaryCard-module--title--HwC-c";
export var titleLarge = "SummaryCard-module--titleLarge--LmcF5";