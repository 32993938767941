import { Form } from "@baloise-cfa/form-renderer-frontend";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Application } from "../../shared.enums";

import { ContactFormTheme } from "./ContactForm.enums";
import { createFields } from "./ContactForm.helpers";
import * as styles from "./ContactForm.module.scss";
import { ContactFormProps, InnerContactFormValue } from "./ContactForm.types";

const ContactForm: React.FC<ContactFormProps> = ({
    initialValue = {},
    professionalUsage,
    showErrorMessageCard = false,
    errorMessageCardTitle,
    errorMessageCardSubTitle,
    application = Application.Simulator,
    theme,
    onSubmit,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
    children,
}) => {
    const { t } = useTranslation();
    const fields = useMemo(() => createFields(application), [application]);
    const initialFormValues: InnerContactFormValue = useMemo(
        () => ({
            context: {
                professionalUsage: professionalUsage,
            },
            data: initialValue,
        }),
        [professionalUsage, initialValue],
    );

    const formClassName = classNames({
        [styles.card]: theme === ContactFormTheme.Card,
        "bal-card": theme === ContactFormTheme.Card,
    });

    return (
        <Form
            formClassName={formClassName}
            showErrorMessageCard={showErrorMessageCard}
            errorMessageCardTitle={errorMessageCardTitle}
            errorMessageCardSubTitle={errorMessageCardSubTitle}
            formContext={{
                t,
                fieldWrapper: {
                    optionalLabel: "all.optional",
                },
                nameSpace: "data",
            }}
            initialValues={initialFormValues}
            fields={fields}
            enableReinitialize
            onSubmit={(values) => onSubmit(values.data)}
            onChange={(values) => onChange(values.data)}
        >
            {children}
        </Form>
    );
};

export default ContactForm;
