import React from "react";

import { TableHeaderProps } from "./TableHeader.types";

const TableHeader: React.FC<TableHeaderProps> = ({
    component,
    hideLabel,
    label,
    className,
}) => {
    // render
    const renderTableHeader = () => {
        if (hideLabel) {
            return;
        }

        if (component) {
            return component(label);
        }

        return <>{label}</>;
    };

    return <th className={className}>{renderTableHeader()}</th>;
};
export default TableHeader;
