import { WtcReason } from "@baloise-cfa/tsclient/mob";
import { TFunction } from "i18next";

export const composeFirstParagraph = (
    wtcReasons: WtcReason[] | undefined,
    t: TFunction,
) => {
    const genericParagraph = t("all.wtccontactme.paragraph1.generic");
    /**
     * Return a generic paragraph when there is no wtc reason
     */
    if (!wtcReasons || wtcReasons.length === 0) {
        return genericParagraph;
    }

    const reasonTexts = wtcReasons.reduce((acc, reason) => {
        if (reason === WtcReason.AccidentHistory) {
            acc.push(t("all.wtccontactme.variable.accidents"));
        }

        if (
            reason === WtcReason.CarValueTooLow ||
            reason === WtcReason.CarValueTooHigh
        ) {
            acc.push(t("all.wtccontactme.variable.carvalue"));
        }

        if (reason === WtcReason.CarTooOld) {
            acc.push(t("all.wtccontactme.variable.carage"));
        }

        return acc;
    }, [] as string[]);

    if (reasonTexts.length === 0) {
        return genericParagraph;
    }

    const replacementText =
        reasonTexts.length === 1
            ? reasonTexts[0]
            : reasonTexts.length === 2
            ? `${reasonTexts[0]} ${t("all.wtccontactme.variable.and")} ${
                  reasonTexts[1]
              }`
            : `${reasonTexts[0]}, ${reasonTexts[1]} ${t(
                  "all.wtccontactme.variable.and",
              )} ${reasonTexts[2]}`;

    return t("all.wtccontactme.paragraph1.variable").replace(
        "$variables",
        replacementText,
    );
};
