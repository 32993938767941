import { differenceInYears } from "date-fns";

import { DiscountExplanationI18nContext } from "./DiscountBlock.enums";

import { MileageOptions } from "@modules/shared/enums";

export const calculateDiscount = (
    amountOfSafetyOptions: number,
    mileage?: number,
    driversLicenseDate?: string,
): {
    totalDiscount: number;
    mileageDiscount?: number;
    safetyOptionsDiscount?: number;
    mileageText?: string;
} => {
    const driversLicenseLessThen5Years = driversLicenseDate
        ? differenceInYears(new Date(), new Date(driversLicenseDate)) < 5
        : true;

    if (driversLicenseLessThen5Years) {
        // only safety options discount when drivers license is less then 5 years
        if (amountOfSafetyOptions > 1) {
            return {
                totalDiscount: 10,
                safetyOptionsDiscount: 10,
            };
        } else {
            return {
                totalDiscount: 0,
            };
        }
    }

    if (amountOfSafetyOptions <= 1) {
        if (mileage === MileageOptions.LessThen15000) {
            return {
                totalDiscount: 5,
                mileageDiscount: 5,
                mileageText: "15.000",
            };
        }

        if (mileage === MileageOptions.LessThen10000) {
            return {
                totalDiscount: 10,
                mileageDiscount: 10,
                mileageText: "10.000",
            };
        }

        if (mileage === MileageOptions.LessThen5000) {
            return {
                totalDiscount: 20,
                mileageDiscount: 20,
                mileageText: "5.000",
            };
        }

        return {
            totalDiscount: 0,
        };
    }

    if (mileage === MileageOptions.Unlimited) {
        return {
            totalDiscount: 10,
            safetyOptionsDiscount: 10,
        };
    }

    if (mileage === MileageOptions.LessThen15000) {
        return {
            totalDiscount: 15,
            mileageDiscount: 5,
            safetyOptionsDiscount: 10,
            mileageText: "15.000",
        };
    }

    if (mileage === MileageOptions.LessThen10000) {
        return {
            totalDiscount: 20,
            mileageDiscount: 10,
            safetyOptionsDiscount: 10,
            mileageText: "10.000",
        };
    }

    if (mileage === MileageOptions.LessThen5000) {
        return {
            totalDiscount: 30,
            mileageDiscount: 20,
            safetyOptionsDiscount: 10,
            mileageText: "5.000",
        };
    }

    return {
        totalDiscount: 0,
    };
};

export const createdDiscountExplanationI18nContext = (
    mileageDiscount?: number,
    safetyOptionsDiscount?: number,
) => {
    if (mileageDiscount && safetyOptionsDiscount) {
        return DiscountExplanationI18nContext.MileageSafetyOptions;
    }

    if (mileageDiscount) {
        return DiscountExplanationI18nContext.Mileage;
    }

    return DiscountExplanationI18nContext.SafetyOptions;
};
