import { BalNavbar } from "@baloise/ds-react";
import classNames from "classnames";
import React from "react";

import * as style from "./NavBar.module.scss";
import { NavBarProps } from "./NavBar.types";

import Logo from "@images/logo.svg";

const NavBar: React.FC<NavBarProps> = ({ left, right }) => {
    return (
        <BalNavbar interface="simple" light className={style.cNavBar}>
            <div className="flex align-items-center">
                <img
                    src={Logo}
                    alt="ford-insurances-logo"
                    className={classNames("mr-x-small", style.cNavBarLogo)}
                />

                {left && <>{left}</>}
            </div>
            {right && (
                <div className="flex align-items-center mr-none ml-auto">
                    <>{right}</>
                </div>
            )}
        </BalNavbar>
    );
};

export default NavBar;
