import axios, { AxiosInstance } from "axios";

import { FordProductPage } from "./productPage.types";

/**
 * Product page service
 * This is a class based singleton
 */
export class ProductPageService {
    private static instance: ProductPageService;
    private client: AxiosInstance;

    /**
     * The constructor should be private
     * when creating a class singleton
     */
    private constructor() {
        this.client = axios.create({
            baseURL: "/api/cms/ford",
        });
    }

    /**
     * Get the current instance
     * @returns this
     */
    public static getInstance(): ProductPageService {
        if (!ProductPageService.instance) {
            ProductPageService.instance = new ProductPageService();
        }

        return ProductPageService.instance;
    }

    /**
     * Get the product page by a given language
     * @param lang - language
     * @returns - ProductPage
     */
    public async getProductPage(lang: string): Promise<FordProductPage> {
        return this.client
            .get<FordProductPage[]>(`productpages?lang=${lang}`)
            .then((result) => result.data[0]);
    }
}
