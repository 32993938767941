import { BalButton, BalCardContent, BalText } from "@baloise/ds-react";
import { useFormContext } from "@baloise-cfa/form-renderer-frontend";
import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";

import * as style from "./CompletedCard.module.scss";
import { CompletedCardProps } from "./CompletedCard.types";

/**
 * CompletedCard
 */
const CompletedCard: React.FC<CompletedCardProps> = ({
    nextPage,
    nextPageBtnText,
    currentPageText,
    language,
    currentPage,
    nextPageAction,
}) => {
    const { t } = useFormContext();

    const nextPageName = nextPageBtnText
        ? t(nextPageBtnText)
        : t(nextPage?.navigation?.title ?? "all.next");

    const currentPageName = currentPageText
        ? t(currentPageText)
        : t(currentPage?.navigation?.title ?? "N/A");

    return (
        <div className={classNames(style.card, "mb-large")}>
            <BalCardContent>
                <div className={style.container}>
                    <div>
                        <svg
                            width="29"
                            height="30"
                            viewBox="0 0 26 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.0117 9.24805L11.375 14.9355L8.9375 12.498C8.42969 12.041 7.66797 12.041 7.21094 12.498C6.70312 13.0059 6.70312 13.7676 7.21094 14.2246L10.4609 17.4746C10.7148 17.7285 11.0195 17.8301 11.375 17.8301C11.6797 17.8301 11.9844 17.7285 12.1875 17.4746L18.6875 10.9746C19.1953 10.5176 19.1953 9.75586 18.6875 9.29883C18.2305 8.79102 17.4688 8.79102 17.0117 9.24805ZM13 0.361328C5.78906 0.361328 0 6.20117 0 13.3613C0 20.5723 5.78906 26.3613 13 26.3613C20.1602 26.3613 26 20.5723 26 13.3613C26 6.20117 20.1602 0.361328 13 0.361328ZM13 23.9238C7.16016 23.9238 2.4375 19.2012 2.4375 13.3613C2.4375 7.57227 7.16016 2.79883 13 2.79883C18.7891 2.79883 23.5625 7.57227 23.5625 13.3613C23.5625 19.2012 18.7891 23.9238 13 23.9238Z"
                                fill="#007521"
                            />
                        </svg>
                    </div>

                    <div>
                        <h4 className={style.heading}>
                            {t("all.form.completed.title")}
                        </h4>

                        {(nextPage || nextPageAction) && (
                            <>
                                <BalText>
                                    {t("all.form.completed.text", {
                                        currentPage: currentPageName,
                                        nextPage: nextPageName,
                                    })}
                                </BalText>
                                <BalButton
                                    rounded
                                    color="info"
                                    onClick={
                                        nextPage
                                            ? () =>
                                                  navigate(
                                                      nextPage?.paths[language],
                                                  )
                                            : nextPageAction
                                    }
                                    iconRight="caret-right"
                                >
                                    {nextPageName}
                                </BalButton>
                            </>
                        )}
                    </div>
                </div>
            </BalCardContent>
        </div>
    );
};

export default CompletedCard;
