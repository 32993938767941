import {
    BalButton,
    BalHeading,
    BalPopover,
    BalPopoverContent,
} from "@baloise/ds-react";
import classNames from "classnames";
import React, { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Language } from "../../../shared.enums";

import * as style from "./LanguageSwitch.module.scss";
import { LanguageSwitchProps } from "./LanguageSwitch.types";

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
    page,
    language,
    prefix,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
}) => {
    const languages = [Language.NL, Language.FR];
    const [usedLanguage, setUsedLanguage] = useState<string>(language);
    const [isPopoverActive, setPopoverActive] = useState<boolean>(false);
    const { t } = useTranslation();
    const checkPrefix = () => {
        return prefix !== "" ? `${global.window.location.origin}${prefix}` : "";
    };

    const getUrl = (language?: Language) => {
        const storedLanguage = global?.window?.localStorage?.getItem(
            "language-key",
        ) as Language;

        const detected = language
            ? language
            : storedLanguage
            ? storedLanguage
            : "nl";

        return `${page.paths[detected]}`;
    };

    const navigateToOtherLanguage = (language: Language) => {
        if (language !== null) {
            setUsedLanguage(language);
            global.window.location.href = `${checkPrefix()}${getUrl(language)}`;
        }
    };

    useEffect(() => {
        global?.window?.localStorage?.setItem(
            "ford-insurance-lg",
            usedLanguage,
        );
    }, [usedLanguage]);

    const toggle = (event: MouseEvent<HTMLBalButtonElement>) => {
        event.stopPropagation();

        setPopoverActive(!isPopoverActive);

        if (window && typeof window.scrollTo === "function") {
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            <BalPopover
                className={style.languageswitcher}
                active={isPopoverActive}
                arrow
                backdrop
                position="bottom-end"
                offsetY={10}
                mobileTop={false}
                onBalChange={(e) => {
                    onChange(e.detail);
                    if (e.detail !== isPopoverActive) {
                        setPopoverActive(e.detail);
                    }
                }}
            >
                <BalButton
                    bal-popover-trigger
                    aria-haspopup={true}
                    square
                    icon-right="nav-go-down"
                    icon-turn={isPopoverActive}
                    color="light"
                    onClick={(evt) => toggle(evt)}
                    className={classNames(
                        style.balNavigationPopoverButton,
                        style.languageTriggerButton,
                        {
                            "bal-navigation-popover__button-primary":
                                isPopoverActive,
                            "bal-navigation-popover__button-light":
                                !isPopoverActive,
                        },
                    )}
                >
                    {usedLanguage.toUpperCase().split("-")[0]}
                </BalButton>
                <BalPopoverContent
                    noShadow={false}
                    expanded={false}
                    mobileTop={false}
                    radius="normal"
                    contentWidth={440}
                    contentMinWidth={440}
                >
                    <div>
                        <BalHeading space="none" level="h4">
                            {t("all.language.choose")}
                        </BalHeading>
                    </div>
                    {languages.map((lng, $index) => (
                        <BalButton
                            rounded
                            outlined
                            data-value={lng}
                            key={$index}
                            expanded={true}
                            color="info"
                            onClick={() => navigateToOtherLanguage(lng)}
                            className={classNames(
                                "mt-x-small",
                                style.languageButton,
                            )}
                        >
                            <>{t(`all.language.${lng}`)}</>
                        </BalButton>
                    ))}
                </BalPopoverContent>
            </BalPopover>
        </>
    );
};

export default LanguageSwitch;
