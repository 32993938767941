import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import {
    SummaryCardAppearance,
    SummaryCardGridDirection,
    SummaryCardTheme,
} from "./SummaryCard.enums";
import * as style from "./SummaryCard.module.scss";
import { SummaryCardProps } from "./SummaryCard.types";

/**
 * SummaryCard
 */
const SummaryCard: React.FC<SummaryCardProps> = ({
    label,
    url,
    theme = SummaryCardTheme.Primary,
    appearance = SummaryCardAppearance.Light,
    list,
    gridDirection = SummaryCardGridDirection.Row,
    children,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className={style.headerWrapper}>
                {label && <div className={style.primaryTitle}>{t(label)}</div>}
                {url && (
                    <button
                        className={style.editButton}
                        onClick={() => navigate(url)}
                        type="button"
                    >
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.341 2.00391L16.005 0.667969C15.5832 0.246094 14.9855 0 14.423 0C13.8605 0 13.2628 0.246094 12.841 0.667969L1.09878 12.4102C0.993309 12.4805 0.958152 12.5859 0.922996 12.6914L0.00893366 17.2969C-0.0613788 17.6836 0.219871 18 0.536277 18C0.571434 18 0.641746 18 0.676902 18L5.28237 17.0859C5.38784 17.0508 5.49331 17.0156 5.59878 16.9102L17.341 5.16797C18.2199 4.28906 18.2199 2.88281 17.341 2.00391ZM4.6144 15.5039L1.97768 16.0312L2.50503 13.3945L3.38393 12.5156V14.625H5.49331L4.6144 15.5039ZM5.07143 12.9375V10.8281L11.2589 4.64062L13.4035 6.75L7.18081 12.9375H5.07143Z"
                                fill="#2861A4"
                            />
                        </svg>
                        {t("all.edit")}
                    </button>
                )}
            </div>

            <div
                className={classNames("p-small", {
                    [style.cardPrimary]: theme === SummaryCardTheme.Primary,
                    [style.card]: theme === SummaryCardTheme.secondary,
                    [style.isRow]:
                        list?.length &&
                        gridDirection === SummaryCardGridDirection.Row,
                    [style.isCol]:
                        list?.length &&
                        gridDirection === SummaryCardGridDirection.Col,

                    "bg-white": appearance === SummaryCardAppearance.Light,
                })}
            >
                {list?.length &&
                    list.map(({ title, description, icon }) => {
                        if (description?.length) {
                            return (
                                <div
                                    className="flex p-small gap-small"
                                    key={title}
                                >
                                    {icon && (
                                        <div className="mt-xx-small">
                                            {icon}
                                        </div>
                                    )}
                                    <dl>
                                        <dt>{t(title)}</dt>
                                        {typeof description === "string" ? (
                                            <dd>{description}</dd>
                                        ) : (
                                            <dd>
                                                <ul>
                                                    {description.map(
                                                        (descriptionItem) => {
                                                            if (
                                                                typeof descriptionItem ===
                                                                "string"
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            descriptionItem
                                                                        }
                                                                    >
                                                                        {
                                                                            descriptionItem
                                                                        }
                                                                    </li>
                                                                );
                                                            }

                                                            if (
                                                                descriptionItem?.title &&
                                                                descriptionItem?.subTitle
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            descriptionItem.title
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                style.liWrapper
                                                                            }
                                                                        >
                                                                            <div>
                                                                                {
                                                                                    descriptionItem.subTitle
                                                                                }
                                                                            </div>
                                                                            <div className="text-small">
                                                                                {`(${descriptionItem.title})`}
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            }
                                                        },
                                                    )}
                                                </ul>
                                            </dd>
                                        )}
                                    </dl>
                                </div>
                            );
                        }
                    })}
                {children && <div className="p-small">{children}</div>}
            </div>
        </div>
    );
};

export default SummaryCard;
