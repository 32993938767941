import { BalButton } from "@baloise/ds-react";
import React from "react";

import { ResetButtonProps } from "./ResetButton.types";

const ResetButton: React.FC<ResetButtonProps> = ({ setModal, children }) => {
    return (
        <>
            <BalButton
                className="mb-none mt-auto ford-reset"
                color="link"
                rounded
                iconRight="arrows-round-left"
                onClick={() => (setModal ? setModal("reset") : false)}
            >
                {children}
            </BalButton>
        </>
    );
};

export default ResetButton;
