import {
    DealerInfo,
    DriverInfo,
    FordPostLeadRequest,
    FordPostLeadRequestContextCategory,
    InsurancePackage,
    LeadInfo,
    SimulationVehicleInfo,
    SimulationVehicleUsage,
    WtcInfo,
} from "@baloise-cfa/tsclient/mob";

import {
    Application,
    CarType,
    FieldName,
    Language,
    LeadInfoKey,
    LeadTypes,
} from "../../shared.enums";
import { AppState, Contact } from "../../state/app";

export const modelDTO = (
    data: AppState,
    language: Language,
    leadInfoKey: LeadInfoKey = LeadInfoKey.ContactMe,
    wtcInfo: WtcInfo | undefined,
    application: Application,
    sendInsurancePackage = true,
): FordPostLeadRequest => {
    const vehicleInfo = getVehicleInfo(data, application);
    const vehicleUsage = getVehicleUsage(data, application);
    const driverInfo = getDriverInfo(data, application, language);
    const leadInfo = getLeadInfo(data, leadInfoKey, language);
    const insurancePackage = getInsurancePackageOptionPrices(data, application);
    const dealerInfo = getDealerInfo(data, application);

    return removeEmptyKeys({
        contextCategory: getContextCategory(
            application === Application.Simulator
                ? data.BeforeWeStart?.cartype
                : data.DealerBeforeWeStart?.cartype,
        ),
        dealerInfo: dealerInfo,
        driverInfo: driverInfo,
        insurancePackage: sendInsurancePackage ? insurancePackage : undefined,
        leadInfo: leadInfo,
        vehicleInfo: vehicleInfo,
        vehicleUsage: vehicleUsage,
        wtcInfo: wtcInfo,
    });
};

export const getContextCategory = (carType?: CarType) => {
    if (carType === "new") {
        return FordPostLeadRequestContextCategory.CarInsuranceNewCar;
    } else if (carType === "secondhand") {
        return FordPostLeadRequestContextCategory.CarInsuranceSecondHandCar;
    } else if (carType === "current") {
        return FordPostLeadRequestContextCategory.CarInsurance;
    }
    return undefined;
};

const removeEmptyKeys = (data: FordPostLeadRequest) => {
    const entries = Object.entries(data).filter(([, value]) => value != null);

    const removeNestedEmpty: any = (data: Record<string, unknown>) => {
        const entries = Object.entries(data).filter(
            ([, value]) => value != null,
        );

        const clean = entries.map(([key, v]) => {
            const value =
                typeof v == "object" && !Array.isArray(v)
                    ? removeNestedEmpty(v)
                    : v;
            return [key, value];
        });

        return Object.fromEntries(clean);
    };

    const clean = entries.map(([key, v]) => {
        const value =
            typeof v == "object" && !Array.isArray(v)
                ? removeNestedEmpty(v)
                : v;
        return [key, value];
    });

    return Object.fromEntries(clean);
};

const formatValuta = (value?: string) => {
    if (!value) return undefined;
    return Number(
        value.replaceAll("€", "").replaceAll(" ", "").replaceAll(".", ""),
    );
};

export const getVehicleInfo = (
    data: AppState,
    application: Application,
): SimulationVehicleInfo | undefined => {
    if (application !== Application.Simulator && data.DealerCarDescription) {
        return {
            fuelType: data.DealerCarDescription.carDescription?.fuel,
            value:
                formatValuta(data.DealerCarDescription.invoicevalue) ??
                formatValuta(data.DealerCarDescription.catalogvalue),
            kwPower: data.DealerCarDescription.carDescription?.power,
            isLightLoadVehicle: data.BeforeWeStart?.lightLoadVehicle ?? false,
            model: data.DealerCarDescription.carDescription?.model,
            safetyFeatures: data.DealerCarDescription.safetyOptions
                ? data.DealerCarDescription.safetyOptions.length
                : 0,
            //TODO: should be type Date
            firstUseDate: data.DealerCarDescription?.registrationdate
                ? (new Date(
                      data.DealerCarDescription.registrationdate,
                  ).toISOString() as unknown as Date)
                : undefined,
        };
    } else if (
        data.BeforeWeStart?.professionalUsage &&
        data.BeforeWeStart?.lightLoadVehicle &&
        data.CarDescriptionProfessional
    ) {
        return {
            fuelType: data.CarDescriptionProfessional.carDescription?.fuel,
            value:
                formatValuta(data.CarDescriptionProfessional.invoicevalue) ??
                formatValuta(data.CarDescriptionProfessional.catalogvalue),
            kwPower: data.CarDescriptionProfessional.carDescription?.power,
            isLightLoadVehicle: data.BeforeWeStart?.lightLoadVehicle ?? false,
            model: data.CarDescriptionProfessional.carDescription?.model,
            //TODO: should be type Date
            firstUseDate: data.CarDescriptionProfessional?.registrationdate
                ? (new Date(
                      data.CarDescriptionProfessional.registrationdate,
                  ).toISOString() as unknown as Date)
                : undefined,
        };
    } else if (data.CarDescription) {
        return {
            fuelType: data.CarDescription.carDescription?.fuel,
            value: data.CarDescription.invoicevalue
                ? formatValuta(data.CarDescription.invoicevalue)
                : formatValuta(data.CarDescription.catalogvalue),
            kwPower: data.CarDescription.carDescription?.power,
            isLightLoadVehicle: data.BeforeWeStart?.lightLoadVehicle ?? false,
            model: data.CarDescription.carDescription?.model,
            safetyFeatures: data.CarDescription.safetyOptions
                ? data.CarDescription.safetyOptions.length
                : 0,
            //TODO: should be type Date
            firstUseDate: data.CarUsage?.registrationdate
                ? (new Date(
                      data.CarUsage.registrationdate,
                  ).toISOString() as unknown as Date)
                : undefined,
        };
    }
};

export const getVehicleUsage = (
    data: AppState,
    application: Application,
): SimulationVehicleUsage => {
    if (application === Application.Simulator) {
        if (data.CarUsage) {
            return {
                professionalUse: data.BeforeWeStart?.professionalUsage ?? false,
                maxYearlyKilometers: data.CarUsage?.mileage,
            };
        } else {
            return {
                professionalUse: data.BeforeWeStart?.professionalUsage ?? false,
            };
        }
    } else {
        return {
            professionalUse:
                data.DealerBeforeWeStart?.professionalUsage ?? false,
        };
    }
};

export const getDriverInfo = (
    data: AppState,
    application: Application,
    language: Language,
): DriverInfo | undefined => {
    const driverData =
        application === Application.Simulator ? data.Driver : data.DealerDriver;

    if (driverData) {
        const cityNames = driverData.city?.value?.names;

        let cityNameForLang;

        if (cityNames) {
            if (language === "nl") {
                cityNameForLang = cityNames["NLD"]
                    ? cityNames["NLD"]
                    : cityNames["FRA"];
            } else if (language === "fr") {
                cityNameForLang = cityNames["FRA"]
                    ? cityNames["FRA"]
                    : cityNames["NLD"];
            }
        }

        return {
            address: {
                bus: driverData.bus ?? "",
                city: cityNameForLang ?? "",
                houseNumber: driverData.houseNumber ?? "",
                postalCode: driverData.city?.value?.postalCode,
                street: driverData.street?.value?.name ?? "",
                streetId: driverData.street?.value?.id ?? "",
            },
            //TODO: should be type Date
            birthDate: driverData?.birthday
                ? (new Date(
                      driverData?.birthday,
                  ).toISOString() as unknown as Date)
                : undefined,
            driverLicenseDate: driverData?.license
                ? (new Date(
                      driverData?.license,
                  ).toISOString() as unknown as Date)
                : undefined,
            claimsAtFault: Number(driverData[FieldName.DamageAmount]),
            lastClaimAtFaultDate:
                driverData[FieldName.DateLastDamage] &&
                Number(driverData[FieldName.DamageAmount]) === 1
                    ? (new Date(
                          driverData[FieldName.DateLastDamage] ?? "",
                      ).toISOString() as unknown as Date)
                    : undefined,
        };
    }
};

const getLeadInfo = (
    data: AppState,
    leadInfoKey: LeadInfoKey,
    language: Language,
): LeadInfo | undefined => {
    const leadType: LeadTypes =
        leadInfoKey === LeadInfoKey.ContactMe
            ? LeadTypes.QuickQuote
            : LeadTypes.QualifiedContact;

    const createLeadInfo = (contactData: Contact) => {
        return {
            thirdPartyLiabilityPrice: data.InsuranceChoice?.priceBA,
            leadType: leadType,
            consent: !!contactData?.consent,
            companyName: contactData?.companyname,
            email: contactData?.email,
            requestorComment: contactData?.remark,
            firstName: contactData?.firstname,
            lastName: contactData?.lastname,
            phone: contactData?.phone,
            language: language === Language.FR ? "FRA" : "NLD",
        };
    };

    if (data.ContactProfessional && leadInfoKey !== LeadInfoKey.ContactPopup) {
        return createLeadInfo(data.ContactProfessional);
    }
    if (data.ContactPopup && leadInfoKey === LeadInfoKey.ContactPopup) {
        return createLeadInfo(data.ContactPopup);
    }
    if (data.ContactMe && leadInfoKey === LeadInfoKey.ContactMe) {
        return createLeadInfo(data.ContactMe);
    }
    if (data.DealerContact && leadInfoKey === LeadInfoKey.DealerContact) {
        return createLeadInfo(data.DealerContact);
    }
    if (data.WTCContactMe && leadInfoKey === LeadInfoKey.WTCContactMe) {
        return createLeadInfo(data.WTCContactMe);
    }
    if (
        data.QuoteWTCContactMe &&
        leadInfoKey === LeadInfoKey.QuoteWTCContactMe
    ) {
        return createLeadInfo(data.QuoteWTCContactMe);
    }
};

const getInsurancePackageOptionPrices = (
    data: AppState,
    application: Application,
): InsurancePackage | undefined => {
    if (application === Application.Dealer) {
        return;
    }
    if (data.InsuranceChoice?.insurance) {
        return {
            ...data.InsuranceChoice.insurance,
            optionalGuarantees: data.InsuranceChoice.extraOptionsResult,
        };
    }
};

const getDealerInfo = (
    data: AppState,
    application: Application,
): DealerInfo | undefined => {
    if (application === Application.Simulator) {
        return undefined;
    }
    if (data.DealerCodePopup) {
        return {
            id: data.DealerCodePopup.dealercode,
            salesPersonEmail: data.DealerCodePopup.dealeremail,
        };
    }
};
