export const calculateDateWithOffset = (
  yearsOffset?: number,
  date?: string
): string => {
  let workingDate;
  if (date) workingDate = new Date(date);
  else workingDate = new Date();
  if (yearsOffset)
    workingDate.setFullYear(workingDate.getFullYear() + yearsOffset);
  return workingDate.toISOString().split("T")[0];
};

export const addDate = (
  input: Date,
  days: number,
  months: number,
  years: number
) => {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(
        input.getFullYear() + years,
        input.getMonth() + months + 1,
        0
      ).getDate()
    )
  );
};

export const subtractFromDate = (
  date: Date,
  years: number,
  months: number,
  days: number
) => {
  date.setFullYear(date.getFullYear() - years);
  date.setMonth(date.getMonth() - months);
  date.setDate(date.getDate() - days);

  return date;
};

export const addToDate = (
  date: Date,
  years: number,
  months: number,
  days: number
) => {
  date.setFullYear(date.getFullYear() + years);
  date.setMonth(date.getMonth() + months);
  date.setDate(date.getDate() + days);

  return date;
};

// Pass date as 2022-01-15
export const getAge = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);

  let age = today.getFullYear() - birthDate.getFullYear();

  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
