import { selector, selectorFamily } from "recoil";

import { ProductPageService } from "./productPage.service";

import { Language } from "@modules/shared/enums";
import { currentLanguageState } from "@modules/shared/state";

/**
 * Fetch the product page based on the current language
 * and cache the response using recoil
 */
export const productPageQuery = selectorFamily({
    key: "productPage",
    get: (lang: Language) => () =>
        ProductPageService.getInstance().getProductPage(lang.substring(0, 2)),
});

/**
 * Fetch the current product page based on the
 * current selected language
 */
export const currentProductPageQuery = selector({
    key: "currentProductPage",
    get: ({ get }) => {
        const currentLang = get(currentLanguageState);
        return currentLang && get(productPageQuery(currentLang));
    },
});
