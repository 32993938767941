import axios, { AxiosInstance } from "axios";

import { EngineVariant } from "./engineVariant.types";

export class EngineVariantService {
    private client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: "/api/vehicle/makes/ford/models",
        });
    }

    public async getEngineVariants(
        model: string,
        type: string,
    ): Promise<EngineVariant[]> {
        return this.client
            .get<EngineVariant[]>(`${model}/${type}/engineVariants`)
            .then((result) => result.data);
    }
}

export const engineVariantService = new EngineVariantService();
