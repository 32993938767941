import classNames from "classnames";
import React from "react";

import * as style from "./PageContainer.module.scss";
import { PageContainerProps } from "./PageContainer.types";
import { PagesContainerColor } from "./PagesContainer.enums";

const PageContainer: React.FC<PageContainerProps> = ({
    children,
    backgroundColor = PagesContainerColor.Grey,
    id,
}) => {
    const backgroundStyle =
        backgroundColor === PagesContainerColor.Grey
            ? style.cPageContainerGrey
            : style.cPageContainerWhite;

    return (
        <section
            id={id}
            className={classNames(style.cPageContainer, backgroundStyle)}
        >
            {children}
        </section>
    );
};

export default PageContainer;
