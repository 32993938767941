export enum Application {
    Simulator = "simulator",
    Dealer = "dealer",
    Quote = "quote",
}

export enum Language {
    NL = "nl",
    FR = "fr",
}

export enum CarType {
    New = "new",
    Current = "current",
    SecondHand = "secondhand",
}

export enum PageNames {
    DealerBeforeWeStart = "DealerBeforeWeStart",
    DealerCarDescription = "DealerCarDescription",
    DealerDriver = "DealerDriver",
    DealerContact = "DealerContact",
    DealerCodePopup = "DealerCodePopup",
    ContactPopup = "ContactPopup",
    BeforeWeStart = "BeforeWeStart",
    CarDescription = "CarDescription",
    CarUsage = "CarUsage",
    Driver = "Driver",
    Insurance = "InsuranceChoice",
    WTCContactMe = "WTCContactMe",
    ContactMe = "ContactMe",
    WTCContactUs = "WTCContactUs",
    ProfessionalContactThankYou = "ProfessionalContactThankYou",
    ContactProfessional = "ContactProfessional",
    CarDescriptionProfessional = "CarDescriptionProfessional",
    DriverProfessional = "DriverProfessional",
    QuoteIntro = "QuoteIntro",
    QuoteDrivers = "QuoteDrivers",
    QuoteAcceptanceQuestions = "QuoteAcceptanceQuestions",
    QuoteVehicle = "QuoteVehicle",
    QuoteUsualDriver = "QuoteUsualDriver",
    QuoteOccasionalDriver = "QuoteOccasionalDriver",
    QuotePolicyHolder = "QuotePolicyHolder",
    QuotePaymentTerms = "QuotePaymentTerms",
    QuoteInsurances = "QuoteInsurances",
    QuoteWTCContactMe = "QuoteWTCContactMe",
}

export enum LeadInfoKey {
    ContactMe = "ContactMe",
    ContactPopup = "ContactPopup",
    DealerContact = "DealerContact",
    WTCContactMe = "WTCContactMe",
    QuoteWTCContactMe = "QuoteWTCContactMe",
}

export enum ModalName {
    Reset = "reset",
    Contact = "contact",
    DealerCode = "dealercode",
    ProductContact = "productcontact",
}

export enum QuoteModalName {
    VehicleEdit = "vehicle",
    UsualDriverEdit = "usualDriver",
    Contact = "contact",
    InsuranceChoice = "insuranceChoice",
}

export enum FieldName {
    Insurance = "insurance",
    ExtraOptions = "extraOptions",
    CarType = "cartype",
    LightLoadVehicle = "lightLoadVehicle",
    Model = "model",
    Fuel = "fuel",
    Power = "power",
    InvoiceValue = "invoicevalue",
    CatalogValue = "catalogvalue",
    SafetyOptions = "safetyOptions",
    ProfessionalUsage = "professionalUsage",
    Mileage = "mileage",
    RegistrationDate = "registrationdate",
    Birthday = "birthday",
    License = "license",
    DamageAmount = "damage-amount",
    DateLastDamage = "datelastdamage",
    Address = "address-search",
    AddressCity = "address-search-city",
    AddressStreet = "address-search-street",
    AddressHousenumber = "address-search-housenumber",
    AddressBusnumber = "address-search-busnumber",
}

export enum FeatureFlags {
    FordEnableQuoteFlow = "ford-enable-quote-flow",
    MaintenanceMode = "maintenance-toggle",
}

export enum LeadTypes {
    QualifiedContact = "qualifiedContact",
    QuickQuote = "quickQuote",
}

export enum OccasionalDrivers {
    One = "One",
    Two = "Two",
}

export enum MileageOptions {
    LessThen5000 = 4999,
    LessThen10000 = 9999,
    LessThen15000 = 14999,
    Unlimited = 20000,
}

export enum FordFieldType {
    DefinitionList = "ford-definition-list",
    CarDescription = "ford-car-description",
    TextBlock = "ford-text-block",
    InfoCard = "ford-info-card",
    Consent = "ford-consent",
    InsuranceChoice = "ford-insurance-choice",
    PriceList = "ford-price-list",
    Valuta = "ford-valuta",
    InsuranceHelper = "ford-insurance-helper",
    CardRadioGroup = "ford-card-radio-group",
    ButtonCheckbox = "ford-button-checkbox",
    DividerTitle = "ford-divider-title",
}
